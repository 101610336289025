import React from "react";
// import actu1 from "../assets/planning_nov1.png";
// import actu2 from "../assets/planning_nov2.png";

function News(props) {
  return (
    <section className="w-100 mx-auto">
      <div className="text-center mt-5">
        <h2>Mes actualités</h2>
      </div>
      <div className="d-flex  mt-5 justify-content-center ">
        <div className="d-flex flex-wrap justify-content-around">
              
              <div className="col p-2 background_alert rounded-border order-md-2">
                <p className="text-white mt-2">
                  À compter du 1er janvier 2025, je ne prends plus de
                  nouveaux·elles client·e·s <br /> pour le service de garde à
                  domicile
                </p>
                <p className="text-white">
                  Si vous êtes déjà client·e, n'hésitez pas à me contacter par
                  mail ou téléphone
                </p>
                <p className="text-white">
                  Pour les autres, voici des collègues que je vous recommande :{" "}
                  <br />-{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://les-pattounes-de-velours.webnode.fr/"
                    className="text-white"
                  >
                    Les pattounes de velours
                  </a>{" "}
                  (Bois-Guillaume / Bihorel / Mont Saint Aignan) <br /> -{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://depattesetdecrocs.fr/"
                    className="text-white"
                  >
                    De pattes et de crocs
                  </a>{" "}
                  (Rouen centre)
                  <br />-{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://amicat76.fr/"
                    className="text-white"
                  >
                    Amicat76
                  </a>{" "}
                  (Malaunay)
                </p>
              </div>
            
          {/* <div className="m-2 d-flex justify-content-center">
            <img
              src={actu1}
              className="actu"
              alt="planning des activités d'octobre 2024, balade collective dimanche 13 et mantrailing samedi 26"
              style={{
                overflow: "hidden",
              }}
            />
          </div>
          <div className="m-2 d-flex justify-content-center">
            <img
              src={actu2}
              className="actu"
              alt="planning des activités d'octobre 2024, balade collective dimanche 13 et mantrailing samedi 26"
              style={{
                overflow: "hidden",
              }}
            />
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default News;
