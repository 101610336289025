import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { API_URL } from "../utils/config";

function PrestationGarde(props) {
  const [backendData, setBackendData] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/website/prestations/${props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setBackendData(data);
      })
      .catch((error) => {
        console.error("Error fetchin data :", error);
      });
  }, []);

  return (
    <div>
      <h1 className="h3 text-center mt-3 mb-3">{props.service}</h1>
      {backendData.map((service, index) => (
        <div
          key={index}
          className="background_blue p-2 rounded-border mb-4 shadow"
        >
          <div className="row ">
            <h2 className="text-center m-4">{service.name}</h2>
          </div>
          <div className="row justify-content-around align-items-center">
            <div className="col-10 col-md-8 col-xl-8 mb-2 order-md-2 mt-2">
              {/* <p
                className="background_white rounded-border p-2"
                dangerouslySetInnerHTML={{
                  __html: service.description.replace(/\n/g, "<br />"),
                }}
              ></p> */}
              <div className="col p-2 background_alert rounded-border order-md-2">
                <p className="text-white mt-2">
                  À compter du 1er janvier 2025, je ne prends plus de
                  nouveaux·elles client·e·s <br /> pour le service de garde à
                  domicile
                </p>
                <p className="text-white">
                  Si vous êtes déjà client·e, n'hésitez pas à me contacter par
                  mail ou téléphone
                </p>
                <p className="text-white">
                  Pour les autres, voici des collègues que je vous recommande :{" "}
                  <br />-{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://les-pattounes-de-velours.webnode.fr/"
                    className="text-white"
                  >
                    Les pattounes de velours
                  </a>{" "}
                  (Bois-Guillaume / Bihorel / Mont Saint Aignan) <br /> -{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://depattesetdecrocs.fr/"
                    className="text-white"
                  >
                    De pattes et de crocs
                  </a>{" "}
                  (Rouen centre)
                  <br />-{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://amicat76.fr/"
                    className="text-white"
                  >
                    Amicat76
                  </a>{" "}
                  (Malaunay)
                </p>
              </div>
            </div>

            <div className="col-12 col-md-3 col-xl-3 order-md-1 p-0 justify-content-around mt-2">
              <div className="row mx-auto pb-2 mb-2 border-bottom">
                <div className="col p-0 d-flex justify-content-center">
                  <div className="circle "></div>
                </div>
                <div className="col p-0 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_white">
                    30min
                  </div>
                </div>
                <div className="col ps-2 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_white">
                    1H
                  </div>
                </div>
              </div>
              <div className="row mx-auto pb-3">
                <div className="col p-0 d-flex justify-content-center align-items-center">
                  <div>1x/jour</div>
                </div>
                <div className="col p-0 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_dark text-light">
                    13€
                  </div>
                </div>
                <div className="col ps-2 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_dark text-light">
                    18€
                  </div>
                </div>
              </div>
              <div className="row mx-auto pb-3">
                <div className="col p-0 d-flex justify-content-center align-items-center">
                  <div>2x/jour</div>
                </div>
                <div className="col p-0 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_dark text-light">
                    25€
                  </div>
                </div>
                <div className="col ps-2 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_dark text-light">
                    35€
                  </div>
                </div>
              </div>
              <div className="row mx-auto">
                <div className="col p-0 d-flex justify-content-center align-items-center">
                  <div>3x/jour</div>
                </div>
                <div className="col p-0 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_dark text-light">
                    35€
                  </div>
                </div>
                <div className="col ps-2 d-flex justify-content-center">
                  <div className="circle rounded-circle shadow background_dark text-light">
                    50€
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-around align-items-center">
            <div className="d-flex col-5 col-md-3 col-xl-2 order-md-1 p-0 justify-content-around"></div>

            {/* <div className="col-5 col-md-8 col-xl-11 order-md-3 mt-2 mt-3 mb-2 text-end">
              <button
                type="button"
                className="btn background_white shadow p-1 fw-bold"
              >
                <NavLink className="nav-link active" to="/contact">
                  Me contacter
                </NavLink>
              </button>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
}

export default PrestationGarde;
